header {
	height: 100px;
	background: url(../images/general/header_bg.png);

	@include tablet {
		height: 150px;
		border-bottom: 60px solid #1A1C23; }

	.container-fluid {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: space-between;
		position: relative;

		@include mobile {

			&::after {
				content: '';
				width: 50px; } } } }

.header__logo {
	margin-right: 20px;

	@include tablet {
		margin-right: 30px; }

	@include mobile {
		margin-right: 10px; } }

.header-logo__link {
	display: block;

	svg {
		width: 128px;
		height: 70px; } }

.header-logo__img {
	width: 70px;

	@include tablet {
		width: 60px; } }

.header__form {
	flex: 1 0 auto;
	max-width: 300px;
	margin-right: 20px;
	position: relative;

	@include tablet {
		position: absolute;
		left: 10px;
		right: 40px;
		bottom: -50px;
		max-width: none; } }

.header-form__input {
	height: 40px;
	border: 1px solid #C1C1C1;
	border-radius: 4px;
	padding-left: 10px;
	padding-right: 10px;
	background: transparent;
	font-family: $base-font-family;
	font-size: 15px;
	line-height: 18px;
	transition-duration: $trds;

	&:hover, &:focus {
		background: var(--white); }

	@include tablet {
		border: 1px solid #656565;
		color: #fff; }

	@include placeholder {
		color: #A3A3A3; } }

.header-form__button {
	width: 38px;
	height: 38px;
	background: transparent;
	border: none;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;

	svg {
		stroke: var(--blue);
		width: 17px;
		height: 17px;

		@include tablet {
			stroke: #A3A3A3; } } }

.header__contacts {
	display: flex;
	flex-direction: column;
	width: 250px;
	margin-right: 20px;

	@include tablet {
		margin-right: auto; }

	@include mobile {
		margin-right: 0px;
		width: auto; } }

.header-contacts__phones {
	display: flex; }

.header-phones__link {
	font-weight: 600;
	font-size: 24px;
	line-height: 31px;
	font-family: $add-font-family;
	color: var(--black);
	text-decoration: none;
	white-space: nowrap;

	@include mobile {
		font-size: 20px;
		line-height: 26px; } }


.header__address {
	font-size: 12px;
	line-height: 14px;
	color: #656565;

	@include mobile {
		display: none; } }

.header__buttons {
	display: flex;

	@include tablet {
		margin-right: 50px; }

	@include mobile {
		margin-right: 0; } }

.header__button + .header__button {
	margin-left: 10px; }

.header__button--recall {

	@include mobile {
		display: none; } }

.header__button--config {

	@include tablet {
		position: absolute;
		right: 10px;
		bottom: -50px;
		width: 40px;
		font-size: 0;
		border: 1px solid #656565;

		svg {
			width: 20px;
			height: 20px;
			min-width: 20px;
			margin-right: 0;
			fill: #A3A3A3; } } }

.nav {
	height: 50px;
	background: #1A1C23;
	position: relative;
	z-index: 2;

	@include tablet {
		display: none; }

	.container-fluid {
		height: 100%; } }

.nav__list {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: space-between; }

.nav__link {
	white-space: nowrap;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	font-family: $add-font-family;
	color: var(--white);
	text-decoration: none;
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	justify-content: center;
	position: relative;
	transition-duration: $trds;

	&:hover {
		color: #7891FF; }

	&::before {
		content: '';
		height: 30px;
		position: absolute;
		right: 0;
		top: 10px;
		width: 1px;
		background: #E2E2E2;
		opacity: .2; } }

.nav__item {
	height: 100%;
	flex: 1 0 auto;

	&:hover {

		.nav__dropmenu {
			opacity: 1;
			visibility: visible;
			margin-top: 0; } }

	&:first-child {

		.nav__link {

			&::after {
				content: '';
				height: 30px;
				position: absolute;
				left: 0;
				top: 10px;
				width: 1px;
				background: #E2E2E2;
				opacity: .2; } } }

	&.parent {

		svg {
			width: 8px;
			height: 6px;
			margin-left: 6px;
			margin-top: 2px; }

		&:hover {

			.nav__dropdown {
				opacity: 1;
				visibility: visible;
				margin-top: 0; } } } }

.nav__dropmenu {
	position: absolute;
	top: 100%;
	z-index: 1;
	background: #1A1C23;
	border-top: 1px solid #424349;
	width: 100%;
	padding: 30px 0;
	left: 0;
	transition-duration: $trds;
	opacity: 0;
	visibility: hidden;
	margin-top: 30px; }

.nav-child__link {
	font-size: 16px;
	line-height: 19px;
	color: #fff;
	text-decoration: none;
	transition-duration: $trds;

	&:hover {
		color: #7891FF; } }

.nav-child__item + .nav-child__item {
	margin-top: 22px; }

.nav-child__item--title {
	border-bottom: 1px solid #424349;
	padding-bottom: 20px;
	margin-bottom: 20px; }

a.open__nav {
	background: var(--blue);
	display: flex;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 4px;
	font-family: $add-font-family;
	color: var(--white);
	text-decoration: none;
	white-space: nowrap;
	transition: .5s ease-in-out;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	display: none;
	position: absolute;
	top: 25px;
	right: 10px;

	@include tablet {
		display: flex; }

	svg {
		width: 26px;
		height: 26px;

		&.icon-close {
			display: none; } }

	@include mobile {
		display: flex; }

	&.open {
		background: #646464;

		.icon-nav {
			display: none; }

		.icon-close {
			display: inline; } }

	span {
		display: block;
		position: absolute;
		height: 4px;
		width: 100%;
		background: #484848;
		border-radius: 3px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;

		&:nth-child(1) {
			top: 0px; }

		&:nth-child(2), &:nth-child(3) {
			top: 7px; }

		&:nth-child(4) {
			top: 14px; } } }
