@font-face {
    font-family: 'Roboto';
    src: local('Roboto Regular'), local('Roboto-Regular'),
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Mont';
    src: local('Mont Semi Bold'), local('Mont-Semi-Bold'),
        url('../fonts/Mont-SemiBold.woff2') format('woff2'),
        url('../fonts/Mont-SemiBold.woff') format('woff'),
        url('../fonts/Mont-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Mont';
    src: local('Mont Regular'), local('Mont-Regular'),
        url('../fonts/Mont-Regular.woff2') format('woff2'),
        url('../fonts/Mont-Regular.woff') format('woff'),
        url('../fonts/Mont-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Mont';
    src: local('Mont Light'), local('Mont-Light'),
        url('../fonts/Mont-Light.woff2') format('woff2'),
        url('../fonts/Mont-Light.woff') format('woff'),
        url('../fonts/Mont-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}