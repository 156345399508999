@mixin size($a,$b: $a) {
  width: $a;
  height: $b;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
		background: $foreground-color;
		border-radius: 4px;
		cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}
@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }
  @include optional-at-root(':-moz-placeholder') {
    @content;
  }
  @include optional-at-root('::-moz-placeholder') {
    @content;
  }
  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}





@mixin width1410 {
  @media (max-width: 1410px) {
    @content;
  }
}

@mixin width1400 {
  @media (max-width: 1400px) {
    @content;
  }
}

@mixin width1360 {
  @media (max-width: 1360px) {
    @content;
  }
}

@mixin width1250 {
  @media (max-width: 1250px) {
    @content;
  }
}

@mixin width1200 {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin width1200 {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin width1100 {
  @media (max-width: 1100px) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin upTablet {
  @media (min-width: 991px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 640px) {
    @content;
  }
}

@mixin upMobile {
  @media (min-width: 759px) {
    @content;
  }
}

@mixin minMobile {
  @media (max-width: 576px) {
    @content;
  }
}
