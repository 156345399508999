.page-car {}

.car {
	padding: 50px 0  20px;

	@include tablet {
		padding-top: 30px; } }

.car__info {
	margin-bottom: 50px; }

.car__gallery {
	margin-bottom: 30px; }

.car-gallery__img {
	display: block;
	width: 100%; }

.car-gallery__thumbs {
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
	margin-top: 15px; }

.car-gallery__thumb {
	width: calc(20% - 12px);
	opacity: .4;
	transition-duration: $trds;

	&:hover, &.active {
		opacity: 1; } }

.car__desc {
	display: flex;
	flex-direction: column;
	align-items: flex-start; }

.car__title {
	margin-bottom: 20px;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;

	@include tablet {
		font-size: 20px;
		line-height: 23px;
		margin-bottom: 10px; } }

.car__price {
	font-size: 36px;
	line-height: 42px;
	color: #2E4090;
	margin-bottom: 20px;

	@include tablet {
		font-size: 32px;
		line-height: 38px;
		margin-bottom: 10px; } }

.car__spec {
	font-size: 16px;
	line-height: 120%;
	margin-bottom: 40px;

	@include tablet {
		font-size: 14px;
		line-height: 16px;
		margin-bottom: 20px; }

	li + li {
		margin-top: 10px; } }

.car__button {
	width: 100%;
	max-width: 300px; }

.car__icons {
	margin-bottom: 50px;

	& > div {

		@include tablet {
			margin-bottom: 20px; } } }

.car__icon {
	border: 1px solid #CECECE;
	padding: 20px 10px;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	transition-duration: $trds;

	&:hover {
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); } }

.car-icon__img {
	margin-bottom: 10px; }

.car-icon__title {
	font-size: 18px;
	line-height: 23px;
	text-align: center;
	font-family: $add-font-family; }

.car__text {
	background: #F3F3F3;
	padding: 40px 0 20px;

	p {
		margin-bottom: 20px; } }

.car__tech {
	padding: 40px 0; }

.car-tech__title {
	margin-bottom: 30px;
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	color: #000000; }

.car__table {
	overflow-y: auto; }

.car__tech {

	table {
		font-size: 14px;
		line-height: 16px;
		color: #333333;
		border-collapse: collapse;

		thead {
			background: var(--blue);

			tr {

				th {
					padding: 10px 0;
					font-weight: 700;
					font-size: 16px;
					line-height: 19px;
					text-align: center;
					color: #FFFFFF; } } }

		tr {
			td {
				border-bottom: 1px solid #E5E5E5;
				padding: 10px;
				text-align: center;

				&:first-child {
					text-align: left; } }

			&.subtitle {
				background: #EFEFEF;
				font-weight: 700;
				font-size: 16px;
				line-height: 19px;
				text-align: center;
				color: #000000;

				td {
					text-align: center; } } } } }
