 :root {
	--red: #E60000;
	--white: #FFFFFF;
	--black: #000000;
	--blue: #2E4090;
	--blue-hover: #3E4F9A; }

@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";

html {
	height: 100%;
	background: #fff; }

body {
	font: 400 16px/19px $base-font-family;
	color: #393939;
	height: 100%;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	min-width: 300px;
	background: transparent; }

*,
*::before,
*::after {
	box-sizing: border-box; }

ul,
ol {
	list-style: none;
	padding: 0; }

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0; }

a:not([class]) {
	text-decoration-skip-ink: auto; }

img {
	_max-width: 100%;
	_display: block; }

input,
button,
textarea,
select {
	font: inherit;
	width: 100%; }

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
	vertical-align: middle; }

textarea {
	resize: none; }

section {
	position: relative; }

input,
select,
button {
	outline: none; }

.d-f {
	display: flex; }

.a-c {
	align-items: center; }

.j-c {
	justify-content: center; }

a {
	color: var(--black);
	transition-duration: $trds;

	&:hover {
		text-decoration: none; } }
.button {
	background: var(--blue);
	border-radius: 4px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
	border: 1px solid var(--blue);
	font-weight: 400;
	font-size: 15px;
	line-height: 19px;
	color: var(--white);
	font-family: $add-font-family;
	text-decoration: none;
	transition-duration: $trds;
	white-space: nowrap;

	.button__icon {
		margin-right: 10px;
		width: 20px;
		height: 20px;
		transition-duration: $trds; }

	svg {
		margin-right: 10px;
		width: 20px;
		height: 20px;
		transition-duration: $trds;
		fill: #fff; }

	&:hover {
		background: var(--blue-hover); } }

.button--transparent {
	background: transparent;
	color: var(--blue);

	svg {
		fill: var(--blue); }

	&:hover {
		color: var(--white);
		background: var(--blue-hover);

		svg {
			fill: var(--white) !important; } } }

.overflow {
	overflow: hidden; }

.input {
	font-family: $base-font-family;
	border: 1px solid #CECECE;
	border-radius: 3px;
	padding-left: 25px;
	padding-right: 25px; }

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100%; }

.content {
	flex: 1 0 auto;
	padding: 50px 0;
	background: transparent;

	@include tablet {
		padding: 40px 0; }

	table {
		border-spacing: 0;
		border: 0; }

	p {
		margin-bottom: 20px; } }



h1 {
	font-weight: 700;
	font-size: 36px;
	line-height: 42px;
	margin-bottom: 40px;

	@include tablet {
		font-size: 24px;
		line-height: 28px;
		margin-bottom: 24px; } }

h2 {
	font-weight: 700;
	font-size: 32px;
	line-height: 44px;
	margin-bottom: 15px;

	@include tablet {
		font-size: 20px;
		line-height: 28px;
		margin-bottom: 15px; } }

h3 {
	text-transform: none;
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
	margin-bottom: 20px;

	@include tablet {
		font-size: 18px;
		margin-bottom: 20px; } }

.h1 {
	font-weight: 500;
	font-size: 32px;
	line-height: 120%;
	color: #121216;
	margin-bottom: 36px;

	@include tablet {
		font-size: 24px;
		margin-bottom: 20px; } }

.h2 {
	font-weight: 500;
	font-size: 32px;
	line-height: 120%;
	color: #121216;
	margin-bottom: 36px; }

.container {
	margin: 0 auto;
	max-width: $content-size;
	width: 100%;
	padding: 0 15px; }

.section {
	padding: 50px 0;
	border-bottom: 1px solid #C1C1C1;

	@include tablet {
		padding: 40px 0; } }

.section__title {
	font-weight: 700;
	font-size: 36px;
	line-height: 42px;
	text-align: center;
	color: #393939;
	margin-bottom: 40px;

	@include tablet {
		font-size: 24px;
		line-height: 28px;
		margin-bottom: 30px; } }

.section__desc {
	text-align: center;
	font-size: 18px;
	line-height: 21px;
	margin-bottom: 30px;

	@include tablet {
		font-size: 16px;
		line-height: 19px; } }

.tab__section {

	.tabs {
		display: flex;
		justify-content: center;
		width: 100%;
		max-width: 450px;
		margin: 0 auto;
		justify-content: space-between;
		margin-bottom: 50px;
		border-bottom: 1px solid #C1C1C1;

		@include tablet {
			margin-bottom: 30px; } }

	.tabs__link {
		width: 220px;
		min-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: $add-font-family;
		font-weight: 400;
		font-size: 15px;
		line-height: 19px;
		text-align: center;
		color: #393939;
		text-decoration: none;
		margin-bottom: -1px;
		padding: 10px;

		&.active {
			background: var(--blue);
			color: #fff; } }

	.tab {

		& > div {
			display: none;

			&.active {
				display: block; } } } }

.popup {
	opacity: 0;
	visibility: hidden;
	z-index: -5;
	transition-duration: $trds;

	&.open {
		opacity: 1;
		visibility: visible; } }

.hidden {
	display: none; }

.popup {
	position: fixed;
	width: 100%;
	max-width: 370px;
	z-index: 101;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	background: #FFFFFF;
	box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	overflow: hidden;

	.form {
		padding: 40px 45px;

		.button {
			cursor: pointer; }

		.button[disabled] {
			cursor: default;
			opacity: .75;

			&:hover {
				background: var(--blue); } } } }

.popup__title {
	height: 70px;
	background: #2E4090;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	line-height: 28px;
	text-align: center;
	color: #FFFFFF;
	font-weight: 400; }

.popup__close {
	position: absolute;
	width: 20px;
	height: 20px;
	right: 10px;
	top: 10px;

	svg {
		width: 16px;
		height: 16px; } }

.form-label__input {
	background: #FFFFFF;
	border: 1px solid #C1C1C1;
	border-radius: 4px;
	padding-left: 10px;
	padding-right: 10px;
	height: 40px;
	font-size: 15px;
	line-height: 18px;
	color: #A3A3A3; }

.form__label {
	margin-bottom: 20px;
	display: block; }

.form__label--checkbox {
	display: flex; }

.form-label__input--checkbox {
	position: absolute;
	z-index: -5;
	opacity: 0;

	&:checked + .form-label__input--checkbox--info {

		&::after {
			content: '';
			width: 14px;
			height: 14px;
			background: var(--blue);
			display: block;
			position: absolute;
			top: 3px;
			left: 3px; } } }

.form-label__input--checkbox--info {
	font-size: 14px;
	line-height: 130%;
	padding-left: 30px;
	position: relative;
	cursor: pointer;
	user-select: none;


	&::before {
		content: '';
		width: 20px;
		height: 20px;
		display: block;
		border: 1px solid #C4C4C4;
		position: absolute;
		top: 0;
		left: 0; } }

.form__success {
	background: #fff;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition-duration: 0.5s;

	&.open {
		display: flex;
		opacity: 1;
		visibility: visible; } }


.form-success__title {
	font-weight: 700;
	font-size: 40px;
	line-height: 48px;
	text-align: center;
	color: #000000;
	margin-bottom: 35px;
	font-family: $add-font-family;
	margin: 25px 0;

	@include mobile {
		font-size: 22px;
		margin-bottom: 20px;
		line-height: 22px; } }

.overlay {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.3);
	display: none; }

.nav__mobile {
	position: fixed;
	z-index: 101;
	left: 0;
	top: 0px;
	bottom: 0;
	width: 100%;
	max-width: 320px;
	background: #1A1C23;
	transform: translateX(-100%);
	transition-duration: $trds;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	text-align: left;
	overflow-x: hidden;

	&.open {
		transform: translateX(0%); } }

.nav-mobile__top {
	height: 50px;
	border-bottom: 1px solid #424349;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-basis: 50px;
	min-height: 50px;
	padding-left: 20px;
	padding-right: 20px; }

.nav-mobile__close {
	display: block;
	margin-left: auto;

	svg {
		width: 18px;
		height: 18px; } }

.nav-mobile__list {
	list-style: none;
	padding: 0;
	margin: 0;
	padding: 20px;
	width: 100%; }

.nav-mobile__item {

	&.parent {

		// &:hover

		// 	.nav-mobile__list-childs
		// 		display: block

		svg {
			width: 12px;
			height: 6px;
			// margin-left: 4px
 } }			// margin-bottom: 6px

	& + .nav-mobile__item {
		margin-top: 20px; } }

.nav-mobile__link {
	font-weight: 400;
	font-size: 18px;
	line-height: 120%;
	color: var(--white);
	text-decoration: none;
	display: flex; }

.nav-mobile__dropmenu {
	position: absolute;
	width: 300px;
	background: #1A1C23;
	top: 50px;
	padding: 20px;
	height: 100%;
	left: 100%;
	transition-duration: $trds;

	&.open {
		left: 0; } }

.bread__mobile {
	align-items: center;
	display: none;

	&.show {
		display: flex; } }

.bread-mobile__link {
	font-size: 13px;
	line-height: 15px;
	color: #FFFFFF;
	text-decoration: none;
	display: flex;
	align-items: center;

	&.bread-mobile__home {

		svg {
			width: 20px;
			height: 20px; } } }

.nav-mobile__list-childs {
	display: none;
	background: #F2F2F2;
	width: calc(100% + 72px);
	margin: 0 -36px;
	padding: 0 36px; }

.nav-mobile-child__link {
	text-decoration: none;
	color: var(--white);
	font-size: 15px;
	line-height: 18px;

	&:hover {
		text-decoration: underline; } }

.nav-mobile-child__item {

	& + .nav-mobile-child__item {
		margin-top: 15px; }

	& + .nav-mobile-child__item--title {
		margin-top: 30px; } }

.nav-mobile-child__item--title {
	border-bottom: 1px solid #424349;
	margin-bottom: 15px;
	padding-bottom: 15px;

	.nav-mobile-child__link {
		font-size: 18px;
		line-height: 21px; } }

.slick-slider {

	.slick-next:before, .slick-prev:before {
		display: none; }

	.slick-slide {

		img {
			width: 100%; } }

	.slick-arrow {
		background: #D9D9D9;
		width: 56px;
		height: 56px;
		border-radius: 100%; }

	.slick-prev, .slick-next {
		background-repeat: no-repeat;
		background-position: 50% 50%;
		z-index: 5;
		cursor: pointer;
		transition-duration: $trds; }

	.slick-prev {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 9L2 5.09756L7 1' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		left: -100px; }

	.slick-next {
		right: -100px;
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 4.90244L1 9' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E"); }

	.slick-prev:focus {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 9L2 5.09756L7 1' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-color: #F2F2F2; }

	.slick-prev:hover {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 9L2 5.09756L7 1' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-color: #F2F2F2; }

	.slick-next:focus {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 4.90244L1 9' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-color: #F2F2F2; }

	.slick-next:hover {
		background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 4.90244L1 9' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-color: #F2F2F2; }

	.slick-dots {
		display: flex;
		justify-content: center;
		bottom: 0px;
		flex-wrap: wrap;
		margin-bottom: 0;

		@include tablet {
			bottom: -38px; }

		li {
			padding: 0;
			margin: 0;
			width: 60px;
			height: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 5px 10px;
			border-radius: 4px;

			@include tablet {
				width: 50px; }

			&.slick-active, &:hover {

				button {
					background: var(--blue);
					width: 60px;
					height: 8px;

					@include tablet {
						width: 50px; } } }

			button {
				background: #DBDBDB;
				width: 60px;
				height: 8px;
				padding: 0;
				transition-duration: $trds;
				border-radius: 4px;

				@include tablet {
					width: 50px; } }

			button:before {
				display: none; } }

		li:before {
			display: none; } } }


//Модули
@import "modules/modules";
