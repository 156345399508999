.map__inner {
	margin-bottom: 30px; }

.content__text--contacts {

	img {
		display: block;
		width: 100%; } }

.button__contacts {
	max-width: 300px; }

.contacts__table {
	overflow-y: auto;
	margin-bottom: 30px;

	table {
		width: 100%; }

	thead {
		background: var(--blue);
		color: #fff;
		line-height: normal;
		text-align: left;

		tr {

			th {
				padding: 20px; } } }

	tr {

		td {
			padding: 20px;
			border-bottom: 1px solid #CECECE; } } }
