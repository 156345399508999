footer {
	background: #000000;
	color: $white;
	padding: 40px 0;

	.container-fluid {
		display: flex;

		@include tablet {
			flex-direction: column; } } }

.footer__counters {
	display: flex;
	margin-right: 100px;

	@include tablet {
		margin-right: 0;
		order: 1;
		margin-top: 40px; } }

.footer__counter + .footer__counter {
	margin-left: 20px; }

.footer__text {
	font-size: 14px;
	line-height: 120%;

	p + p {
		margin-top: 20px; } }
