.page-catalog {}

.catalog {
	padding-top: 30px; }

.catalog__car {
	border: 1px solid #CECECE;
	margin-bottom: 30px;
	transition-duration: $trds;

	&:hover {
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15); } }

.catalog-car__info {
	padding: 12px 20px 25px; }

.catalog-car__title {
	font-family: $add-font-family;
	font-weight: 400;
	font-size: 21px;
	line-height: 120%;
	margin-bottom: 10px;
	transition-duration: $trds;
	text-decoration: none;
	display: block;

	&:hover {
		color: #2E4090; } }

.catalog-car__img {
	display: block;
	width: 100%; }

.catalog-car__spec {
	font-weight: 400;
	font-size: 14px;
	line-height: 120%;
	margin-bottom: 15px;

	li + li {
		margin-top: 10px; } }

.catalog-car__price {
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	margin-bottom: 15px; }

.catalog-car__buttons {
	display: flex;
	gap: 10px; }

.catalog-car__button {
	flex: 1 0; }

.catalog-car__button--more {
	background: transparent;
	border: 1px solid #CECECE;
	color: #393939;

	&:hover {
		background: var(--blue);
		color: var(--white);
		border-color: var(--blue); } }
