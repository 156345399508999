.page-inner {}

.breadcrumbs {
	background: #F3F3F3; }

.breadcrumb {
	display: flex;
	height: 50px;
	align-items: center;
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;


	a {
		text-decoration: none;
		font-weight: 400;
		font-size: 15px;
		line-height: 18px;
		color: #393939;
		display: flex;
		align-items: center;

		&:hover {
			color: #2E4090; } }

	svg {
		width: 10px;
		height: 10px; }

	li {

		&:last-child {

			a:after {
				display: none; } }


		a:after {
			content: '';
			margin: 0 17px;
			width: 6px;
			height: 12px;
			display: block;
			background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1 1L7 7L1 13' stroke='%232E4090'/%3e%3c/svg%3e ") no-repeat;
			background-size: 100% 100%; } } }

h1.page--title {
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid #CECECE; }

.content__text {
	line-height: 150%;

	ul {
		margin-bottom: 30px;

		li {
			display: flex;
			padding-left: 48px;
			position: relative;

			& + li {
				margin-top: 10px; }

			&:before {
				content: '';
				display: block;
				width: 8px;
				height: 8px;
				background: #FF3D00;
				border-radius: 50%;
				position: absolute;
				left: 20px;
				top: 4px; } } } }

.block__list {
	display: flex;
	gap: 30px;
	margin-bottom: 30px;
	flex-wrap: wrap;
	display: grid;
	grid-template-columns: repeat(4, 1fr);

	@include tablet {
		gap: 20px;
		grid-template-columns: repeat(2, 1fr); }

	@include mobile {
		grid-template-columns: repeat(1, 1fr); }

	&.block__list--min {
		margin-bottom: 60px;

		@include tablet {
			display: flex;
			margin-bottom: 40px; }

		.block__link {
			font-size: 16px;
			line-height: normal;

			@include tablet {
				padding: 10px 20px; }

			@include mobile {
				font-size: 14px;
				padding: 10px; } } } }


.block__item {

	@include tablet; }


.block__link {
	display: flex;
	border: 1px solid #CECECE;
	padding: 20px;
	font-size: 20px;
	font-weight: 500;
	text-decoration: none;
	justify-content: center;
	align-items: center;
	font-family: $add-font-family;
	transition-duration: $trds;

	&:hover {
		border-color: #2E4090;
		background: #2E4090;
		color: #fff; }

	@include tablet {
		font-size: 18px; } }

.mark-inner__wrap {
	background: #2E4090;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-bottom: -50px; }

.mark-inner__list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px 30px;

	@include tablet {
		display: flex;
		flex-wrap: wrap;
		gap: 20px; } }

.mark-inner__link {
	display: flex;
	padding: 10px 20px;
	justify-content: center;
	font-size: 16px;
	font-family: $add-font-family;
	text-decoration: none;
	color: #fff;
	border: 1px solid #fff;
	text-align: center;
	transition-duration: $trds;
	height: 100%;
	align-items: center;
	line-height: normal;

	@include tablet {
		font-size: 14px; }

	&:hover {
		background: #fff;
		color: #2E4090; } }

.service__list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px 30px;

	@include tablet {
		grid-template-columns: repeat(2, 1fr);
		gap: 10px 20px; }

	@include mobile {
		display: none; } }

.service__item {
	border: 1px solid #CECECE;
	display: flex;
	min-height: 40px;
	text-decoration: none;
	transition-duration: $trds;

	&:hover {
		border-color: #FF3D00;
		color: #FF3D00;

		.service-item__icon {
			background: #FF3D00; } } }

.service-item__icon {
	background: #000;
	background: #2E4090;
	width: 40px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 40px;
	transition-duration: $trds; }

.service-item__title {
	flex: 1 0;
	font-size: 14px;
	font-family: $add-font-family;
	text-align: center;
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	line-height: normal; }

.contact--title {
	font-size: 24px;
	line-height: normal;
	color: #2E4090;
	font-weight: 700; }

.line {
	height: 1px;
	background: #CECECE;
	margin-bottom: 30px; }


