.header__slider {

	&.slick-dotted.slick-slider {
		margin-bottom: 0;

		@include tablet {
			margin-bottom: 25px; } } }

.avail__slider {
	width: calc(100% + 30px);
	margin: 0 -15px;

	.slick-list {
		padding-bottom: 20px; }

	@include tablet {
		width: calc(100% + 20px);
		margin: 0 -10px;

		&.slick-dotted.slick-slider {
			margin-bottom: 0; } }

	@include mobile {
		margin: 0;
		width: 100%; }

	.slick-dots {
		position: relative;
		margin-top: 1 0px;
		margin-bottom: 50px;

		@include tablet {
			margin-top: 0;
			margin-bottom: 30px;
			bottom: 0; } } }

.avail__item {
	display: flex !important;
	flex-wrap: wrap; }

.avail__car {
	border: 1px solid #E2E2E2;
	position: relative;
	width: calc(25% - 30px);
	margin: 0 15px;
	transition-duration: $trds;

	&:hover {
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15); }

	@include tablet {
		width: calc(50% - 20px);
		margin: 0 10px;
		margin-bottom: 20px; }

	@include mobile {
		width: 100%;
		margin: 0;
		margin-bottom: 20px; } }

.avail-car__year {
	position: absolute;
	background: var(--blue);
	font-size: 15px;
	line-height: 18px;
	text-align: center;
	color: #FFFFFF;
	padding: 2px 10px;
	top: 0;
	right: 0; }

.avail-car__desc {
	padding: 10px 20px; }

.avail-car__title {
	font-size: 16px;
	line-height: 19px;
	color: #393939;
	margin-bottom: 10px;
	display: block;
	text-decoration: none;

	&:hover {
		color: #2E4090; } }

.avail-car__avail {
	font-size: 14px;
	line-height: 16px;
	color: #656565;
	margin-bottom: 10px; }

.avail-car__price {
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	color: #393939; }

.avail__button {
	width: 200px;
	margin: 0 auto; }

.section__partners {
	padding-bottom: 20px; }

.partner__title {
	font-size: 18px;
	line-height: 21px;
	text-align: center;
	margin-bottom: 20px; }

.partner__list {
	margin-bottom: 30px; }

.partner__item {
	height: 100px;
	border: 1px solid #CECECE;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	padding: 10px;
	transition-duration: $trds;

	&:hover {
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); } }

.partner__img {
	max-height: 100%;
	max-width: 100%; }

.bank__list {
	justify-content: center;

	@include tablet {
		justify-content: flex-start; } }

.bank__item {
	height: 100px;
	border: 1px solid #E2E2E2;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	padding: 10px;
	transition-duration: $trds;

	&:hover {
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); } }

.bank__img {
	max-height: 100%;
	max-width: 100%; }

.catalog__main {
	background: url(../images/general/header_bg.png) #F3F3F3;
	margin-top: -1px;
	border-bottom: none; }

.target__list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
	width: calc(100% + 30px);

	@include tablet {
		margin: 0 -10px;
		width: calc(100% + 20px); } }

.target__item {
	width: calc(20% - 30px);
	margin: 0 15px;
	height: 50px;
	background: var(--white);
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 700;
	margin-bottom: 20px;
	transition-duration: $trds;

	&:hover {
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); }

	@include tablet {
		margin: 0 10px;
		width: calc(25% - 20px);
		margin-bottom: 10px; }

	@include mobile {
		width: calc(50% - 20px); } }

.mark__list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
	width: calc(100% + 30px);

	@include tablet {
		margin: 0 -10px;
		width: calc(100% + 20px); } }

.mark__item {
	width: calc(20% - 30px);
	margin: 0 15px;
	background: var(--white);
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	flex-direction: column;
	text-decoration: none;
	margin-bottom: 30px;
	padding: 26px 10px;
	transition-duration: $trds;

	&:hover {
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); }

	@include tablet {
		margin: 0 10px;
		width: calc(25% - 20px);
		margin-bottom: 10px; }

	@include mobile {
		width: calc(50% - 20px); } }

.mark__img {
	margin-bottom: 10px;
	max-width: 100%; }

.mark__title {
	font-weight: 700;
	text-align: center;

	@include mobile {
		font-size: 15px;
		line-height: 18px; } }

.news__top {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	align-items: center;

	@include tablet {
		margin-bottom: 20px; } }

.news__title {
	font-weight: 700;
	font-size: 36px;
	line-height: 42px;

	@include tablet {
		font-size: 24px;
		line-height: 28px; } }

.news__more {
	display: flex;
	align-items: center;
	text-decoration: none;
	justify-content: center;
	width: 130px;
	height: 30px;
	border: 1px solid #A3A3A3;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #656565;
	transition-duration: $trds;

	&:hover {
		background: var(--blue);
		color: var(--white); } }

.news__img {
	display: block;
	width: 100%;
	margin-bottom: 20px; }

.news__item {
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid #E2E2E2;
	display: flex;
	flex-direction: column;
	align-items: flex-start; }

.news__name {
	display: block;
	font-weight: 700;
	font-size: 21px;
	line-height: 25px;
	margin-bottom: 10px;
	text-decoration: none;


	&:hover {
		color: #2E4090; }

	@include tablet {
		font-size: 20px;
		line-height: 23px; } }

.news__intro {
	font-size: 18px;
	line-height: 21px;
	margin-bottom: 10px;
	text-decoration: none;

	&:hover {
		color: #2E4090; }

	@include tablet {
		font-size: 16px;
		line-height: 19px; } }

.news__date {
	background: var(--blue);
	font-size: 18px;
	line-height: 21px;
	text-align: center;
	color: #FFFFFF;
	padding: 4px 10px; }

